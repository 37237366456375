import React, { Component } from "react";
import onClickOutside from "react-onclickoutside";

import webService from "../../../utils/webservice";
import history from "../../../utils/history";
import eventsHub from "../../../utils/eventshub";

class UserMenu extends Component {
  state = {
    showDropdown: false,
    userName: "",
  };
  onUserNameChanged = null;

  componentDidMount() {
    this.onUserNameChanged = eventsHub.onUserNameChanged().subscribe(value => {
      this.setState({ userName: value });
    });
  }

  componentWillUnmount() {
    this.onUserNameChanged.unsubscribe();
    this.onUserNameChanged = null;
  }

  handleClickOutside = evt => {
    this.setState({ showDropdown: false });
  };

  toogleMenu() {
    this.setState({ showDropdown: !this.state.showDropdown });
  }

  closeMenu() {
    this.setState({ showDropdown: false });
  }

  logout() {
    webService.logout();
    history.push("/login");
  }

  render() {
    let ulClassName = "navbar-nav navbar-nav-user";
    if (this.props.className) {
      ulClassName += " " + this.props.className;
    }

    // eslint-disable-next-line
    return (
      <ul className={ulClassName}>
        <li className={"nav-item nav-item-button " + (this.state.showDropdown ? "active" : "")}>
          <button type="button" className="nav-link" onClick={this.toogleMenu.bind(this)}>
            <i className="fa fa-user-circle" />
          </button>
          <div className="dropdown-menu dropdown-menu-right">
            <div className="dropdown-item-text dropdown-item-user">
              <i className="fa fa-user-circle" />
              <div>{this.state.userName}</div>
            </div>
            <button type="button" className="dropdown-item" onClick={this.logout.bind(this)}>
              SALIR
            </button>
          </div>
        </li>
      </ul>
    );
  }
}

export default onClickOutside(UserMenu);
