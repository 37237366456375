import { BehaviorSubject, Subject } from "rxjs";

class EventsHub {
  fichaEmpresaChangedSubject;
  loggedInSubject;
  userNameSubject;
  userRoleSubject;
  showHistorialTransacciones;
  ShowFichaHistorico;

  constructor() {
    this.fichaEmpresaChangedSubject = new BehaviorSubject(null);
    this.loggedInSubject = new BehaviorSubject(false);
    this.userNameSubject = new BehaviorSubject("");
    this.userRoleSubject = new BehaviorSubject("");
    this.showHistorialTransacciones = new Subject();
    this.ShowFichaHistorico = new Subject();
  }

  setFichaEmpresa(fichaEmpresa) {
    this.fichaEmpresaChangedSubject.next(fichaEmpresa);
  }
  onFichaEmpresaChanged() {
    return this.fichaEmpresaChangedSubject.asObservable();
  }


  setLoggedIn(loggedIn) {
    this.loggedInSubject.next(loggedIn);
  }
  onLoggedInChanged() {
    return this.loggedInSubject.asObservable();
  }

  setUserName(userName) {
    this.userNameSubject.next(userName);
  }

  setUserRole(userRole) {
    return this.userRoleSubject.next(userRole);
  }

  onUserNameChanged() {
    return this.userNameSubject.asObservable();
  }

  onUserRoleChanged() {
    return this.userRoleSubject.asObservable();
  }

  setShowHistorialTransacciones(cuit) {
    this.showHistorialTransacciones.next(cuit);
  }
  onShowHistorialTransaccionesChanged() {
    return this.showHistorialTransacciones.asObservable();
  }
  onShowFichaHistoricoChanged() {
    return this.ShowFichaHistorico.asObservable();
  }

  setShowFichaHistorico({cuit, transaccion}) {
    this.ShowFichaHistorico.next({cuit, transaccion});
  }
}

const eventsHub = new EventsHub();

export default eventsHub;
