import React, { Component } from "react";
import { Router, Route, Redirect, Switch } from "react-router-dom";
import IdleTimer from 'react-idle-timer';

import "./App.scss";

import PageLogin from "./pages/login/login";
import DefaultLayout from "./components/layouts/defaultlayout/defaultlayout";
import routes from "./utils/routes";
import history from "./utils/history";
import webService from "./utils/webservice";

class App extends Component {
  
  constructor(props) {
    super(props)
    this.idleTimer = null
    this.onIdle = this._onIdle.bind(this)
  }

  componentDidMount() {

  }

  componentWillUnmount() {

  }

  render() {
    const componentStateNotLoggedIn = () => {
      if (!webService.isLoggedIn()) {
        return <PageLogin />;
      } else {
        history.replace("/dashboard");
        return null;
      }
    };

    const componentStateLoggedIn = () => {
      if (webService.isLoggedIn()) {
        return (
          <DefaultLayout showSidebar={true}>
            <Switch>
              {routes.map(route => {
                return route.component ? (
                  <Route
                    key={route.name}
                    path={route.path}
                    exact={route.exact}
                    name={route.name}
                    render={props => <route.component {...props} />}
                  />
                ) : null;
              })}
              <Redirect from="/" to="/dashboard" />
            </Switch>
          </DefaultLayout>
        );
      } else {
        history.replace("/login");
        return null;
      }
    };

    return (
      <React.Fragment>
        <IdleTimer
          ref={ref => { this.idleTimer = ref }}
          element={document}
          onIdle={this.onIdle}
          debounce={250}
          timeout={1000 * 60 * 30} />
        <Router history={history}>
          <Switch>
            <Route exact path="/login" name="Login Page" component={componentStateNotLoggedIn} />
            <Route path="/" name="Home" component={componentStateLoggedIn} />
          </Switch>
        </Router>
      </React.Fragment>

    );
  }

  _onIdle(e) {
    webService.logout();
    history.push("/login");
  }

}

export default App;
