import axios from "./axios";
import config from "./config";
import eventsHub from "./eventshub";
import moment from "moment";
import FileSaver from "file-saver";
import WebServiceCache from "./cache";

class WebService {
  _headers;
  _authorization;

  constructor() {
    this._headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    this._authorization = localStorage.getItem("authorization");
    eventsHub.setLoggedIn(this.isLoggedIn());
    if (this.isLoggedIn()) {
      this.getUser().then(
        user => [eventsHub.setUserName(user.name), eventsHub.setUserRole(user.roles)]
      );
    }
  }

  getHeaders() {
    let headers = {
      ...this._headers,
    };
    if (this._authorization) {
      headers.Authorization = this._authorization;
    }
    return headers;
  }

  login(email, password) {
    const options = {
      method: "POST",
      data: {
        username: email,
        password: password,
        grant_type: "password",
        client_id: config.clientId,
        client_secret: config.clientSecret,
      },
    };
    return axios("/oauth/token", {
      headers: this.getHeaders(),
      ...options,
    }).then(response => {
      if (response.data.token_type && response.data.access_token) {
        this._authorization = response.data.token_type + " " + response.data.access_token;
        localStorage.setItem("authorization", this._authorization);
        eventsHub.setLoggedIn(true);

        this.getUser().then(
          user => [eventsHub.setUserName(user.name), eventsHub.setUserRole(user.roles)]
        );
      }
      return response;
    });
  }

  logout() {
    localStorage.removeItem("authorization");
    this._authorization = null;
    localStorage.clear()
    eventsHub.setLoggedIn(false);
  }

  isLoggedIn() {
    return this._authorization !== null;
  }

  getUser() {
    return axios.get("/api/whoami", { headers: this.getHeaders() }).then(response => response.data);
  }

  getPyMesStatus() {
    return axios.get("/api/status", { headers: this.getHeaders() }).then(response => response.data);
  }

  getEmpresaFicha(cuit, numeroTransaccion = "") {
    let route = "/api/empresas/ficha/" + cuit;
    let params = {};
    if (numeroTransaccion !== "") {
      params.transaccion = numeroTransaccion;
    }
    return axios.get(route, { params, headers: this.getHeaders() }).then(response => response.data);
  }

  getPyMesStatusDetails(
    state,
    page,
    displayCount,
    cuit,
    transaccion,
    desde,
    hasta,
    motivo,
    orderCol,
    orderDir,
    cachedSecs = 15,
  ) {
    let params = {
      skip: displayCount * page,
      limit: displayCount,
    };
    if (cuit) {
      params.cuit = cuit;
    }
    if (transaccion) {
      params.transaccion = transaccion;
    }
    if (desde) {
      params.desde = moment(desde, "DD/MM/YY").format("YYYY-MM-DD");
    }
    if (hasta) {
      params.hasta = moment(hasta, "DD/MM/YY").format("YYYY-MM-DD");
    }
    if (motivo) {
      params.motivo = motivo;
    }
    if (orderCol) {
      params["orderBy[campo]"] = orderCol;
      if (orderDir) {
        params["orderBy[orden]"] = orderDir === 1 ? "asc" : orderDir === -1 ? "desc" : null;
      }
    }
    let cachedObj = WebServiceCache.getCached("/api/empresas/" + state, params);
    if (cachedObj) {
      return cachedObj.then(response => response.data);
    }
    return axios
      .get("/api/empresas/" + state, { params, headers: this.getHeaders() })
      .then(response => WebServiceCache.setCached("/api/empresas/" + state, params, response.data, cachedSecs));
  }

  getPyMesStatusMotivos(estado) {
    return axios.get("/api/motivos/" + estado, { headers: this.getHeaders() }).then(response => response.data.motivos);
  }

  getSolicitudModificacionTable(page, displayCount, cuit, transaccion, desde, hasta, motivo, orderCol, orderDir) {
    let params = {
      skip: displayCount * page,
      limit: displayCount,
    };
    if (cuit) {
      params.cuit = cuit;
    }
    if (transaccion) {
      params.transaccion = transaccion;
    }
    if (desde) {
      params.desde = moment(desde, "DD/MM/YY").format("YYYY-MM-DD");
    }
    if (hasta) {
      params.hasta = moment(hasta, "DD/MM/YY").format("YYYY-MM-DD");
    }
    if (motivo) {
      params.motivo = motivo;
    }
    if (orderCol) {
      params["orderBy[campo]"] = orderCol;
      if (orderDir) {
        params["orderBy[orden]"] = orderDir === 1 ? "asc" : orderDir === -1 ? "desc" : null;
      }
    }
    return axios
      .get("/api/solicitudmodificacioncategorizacion", { params, headers: this.getHeaders() })
      .then(response => response.data);
  }

  getBajasTable(page, displayCount, cuit, transaccion, desde, hasta, estado, orderCol, orderDir) {
    let params = {
      skip: displayCount * page,
      limit: displayCount,
    };
    if (cuit) {
      params.cuit = cuit;
    }
    if (transaccion) {
      params.transaccion = transaccion;
    }
    if (desde) {
      params.desde = moment(desde, "DD/MM/YY").format("YYYY-MM-DD");
    }
    if (hasta) {
      params.hasta = moment(hasta, "DD/MM/YY").format("YYYY-MM-DD");
    }
    if (estado) {
      params.estado = estado;
    }
    if (orderCol) {
      params["orderBy[campo]"] = orderCol;
      if (orderDir) {
        params["orderBy[orden]"] = orderDir === 1 ? "asc" : orderDir === -1 ? "desc" : null;
      }
    }
    return axios.get("/api/circuitobaja", { params, headers: this.getHeaders() }).then(response => response.data);
  }

  getHistorialTransaccionesTable(cuit) {
    return axios.get("/api/transacciones/" + cuit, { headers: this.getHeaders() }).then(response => response.data);
  }

  getSectores() {
    return axios.get("/api/sectores", { headers: this.getHeaders() }).then(response => response.data.sectores);
  }

  getCategorias() {
    return axios.get("/api/categorias", { headers: this.getHeaders() }).then(response => response.data.categorias);
  }

  getMotivoBajas() {
    return axios.get("/api/circuitobaja/motivos", { headers: this.getHeaders() }).then(response => response.data);
  }

  patchAprobarSolicitudModif(cuit, transaccion) {
    let data = {
      cuit: cuit,
      transaccion: transaccion,
    };
    return axios
      .patch("/api/solicitudmodificacioncategorizacion/aprobar", data, { headers: this.getHeaders() })
      .then(response => response.data);
  }

  patchRechazarSolicitudModif(cuit, transaccion) {
    return axios
      .patch(
        "/api/solicitudmodificacioncategorizacion/rechazar/" + cuit + "/" + transaccion,
        {},
        { headers: this.getHeaders() },
      )
      .then(response => response.data);
  }

  patchRechazarBajaRectif(cuit, transaccion) {
    return axios
      .patch("/api/circuitobaja/rechazar/" + cuit + "/" + transaccion, {}, { headers: this.getHeaders() })
      .then(response => response.data);
  }

  postCircuitoBaja(cuit, transaccion, motivo, categoria, sector, sector_texto, observacion, nro_gde = null) {
    let data = {
      cuit: cuit,
      transaccion: transaccion,
      motivo: motivo,
      categoria: categoria,
      sector: sector,
      sector_texto: sector_texto,
      observacion: observacion,
    };
    if (nro_gde) {
      data.nro_gde = nro_gde;
    }
    return axios.post("/api/circuitobaja", data, { headers: this.getHeaders() }).then(response => response.data);
  }

  postSolicitudModificacionCategorizacionSolicitar(
    cuit,
    transaccion,
    isPyme,
    categoria,
    sector,
    sector_texto,
    status,
    motivo,
    observacion,
    tipoSolicitud,
  ) {
    let data = {
      tipoSolicitud: tipoSolicitud,
      solicitudModificacion: {
        isPyme: isPyme,
        categoria: categoria,
        sector: sector,
        sector_texto: sector_texto,
        status: status,
        motivo: motivo,
        observacion: observacion,
      },
      cuit: cuit,
      transaccion: transaccion,
    };
    return axios
      .post("/api/solicitudmodificacioncategorizacion/solicitar", data, { headers: this.getHeaders() })
      .then(response => response.data);
  }

  getBajasEmpresas(page, displayCount, cuit, estado, desde, hasta, orderCol, orderDir) {
    let params = {
      skip: displayCount * page,
      limit: displayCount,
    };
    if (cuit) {
      params.cuit = cuit;
    }
    if (estado) {
      params.estado = estado;
    }
    if (desde) {
      params.desde = moment(desde, "DD/MM/YY").format("YYYY-MM-DD");
    }
    if (hasta) {
      params.hasta = moment(hasta, "DD/MM/YY").format("YYYY-MM-DD");
    }
    if (orderCol) {
      params["orderBy[campo]"] = orderCol;
      if (orderDir) {
        params["orderBy[orden]"] = orderDir === 1 ? "asc" : orderDir === -1 ? "desc" : null;
      }
    }
    return axios.get("/api/circuitobaja", { params, headers: this.getHeaders() }).then(response => response.data);
  }

  downloadFile(method, url, filename = "") {
    return axios({
      url: url,
      method: method,
      headers: this.getHeaders(),
      responseType: "blob",
    }).then(response => {
      let contentDisposition = response.headers["content-disposition"];
      if (contentDisposition && filename === "") {
        let search = 'filename="';
        let start = contentDisposition.search(search) + search.length;
        let end = 0;
        for (let i = start; i < contentDisposition.length; i++) {
          if (contentDisposition[i] === '"') {
            end = i;
            break;
          }
        }
        FileSaver.saveAs(new Blob([response.data]), contentDisposition.slice(start, end));
      } else {
        FileSaver.saveAs(new Blob([response.data]), filename);
      }
    });
  }

  getCertificadosTable(page, displayCount, cuit, transaccion, desde, hasta, orderCol, orderDir) {
    let params = {
      skip: displayCount * page,
      limit: displayCount,
    };
    if (cuit) {
      params.cuit = cuit;
    }
    if (transaccion) {
      params.transaccion = transaccion;
    }
    if (desde) {
      params.desde = moment(desde, "DD/MM/YY").format("YYYY-MM-DD");
    }
    if (hasta) {
      params.hasta = moment(hasta, "DD/MM/YY").format("YYYY-MM-DD");
    }
    if (orderCol) {
      params["orderBy[campo]"] = orderCol;
      if (orderDir) {
        params["orderBy[orden]"] = orderDir === 1 ? "asc" : orderDir === -1 ? "desc" : null;
      }
    }
    return axios.get("/api/certificados", { params, headers: this.getHeaders() }).then(response => response.data);
  }

  patchSolicitudModificacionCertificado(cuit, transaccion, fechaInformado, desde, hasta, observaciones) {
      let data = {
        cuit: cuit,
        transaccion: transaccion,
        fechaInformado: fechaInformado,
        desde: desde,
        hasta: hasta,
        observaciones: observaciones,
      };
      return axios
      .patch("/api/certificado", data, { headers: this.getHeaders() })
      .then(response => response.data);
  }

  patchRechazarSolicitudModifCertificado(cuit, transaccion) {
    let data = {
      cuit: cuit,
      transaccion: transaccion,
    };
    return axios
    .patch("/api/certificado/rechazar", data, { headers: this.getHeaders() })
    .then(response => response.data);
  }

  patchAceptarSolicitudModifCertificado(cuit, transaccion) {
    let data = {
      cuit: cuit,
      transaccion: transaccion,
    };
    return axios
    .patch("/api/certificado/aceptar", data, { headers: this.getHeaders() })
    .then(response => response.data);
  }

  getCertificadoByCuitTransaccion(cuit, transaccion) {
    return axios
    .get('/api/certificado/' + cuit + '/' + transaccion, { headers: this.getHeaders() })
    .then(response => response.data);
  }

  getCanillaAfip() {
    return axios
    .get('/api/afip/canilla', {headers: this.getHeaders()})
    .then(response => response.data);
  }

  postCanillaAfip() {
    let data = {

    };

    return axios
    .post('/api/afip/canilla', data, {headers: this.getHeaders()})
    .then(response => response.data);
  }

  getCanillaSeti() {
    return axios
    .get('/api/afip/canillaSeti', {headers: this.getHeaders()})
    .then(response => response.data);
  }

  postCanillaSeti() {
    let data = {

    };

    return axios
    .post('/api/afip/canillaSeti', data, {headers: this.getHeaders()})
    .then(response => response.data);
  }

  postReprocesar(idraw) {
    let data = {

    };

    return axios
    .post('/api/core/'+ idraw, data ,{headers: this.getHeaders()})
    .then(response => response.data);
  }

  postAltaManual(idraw) {
    let data = {

    };

    return axios
    .post('/api/empresas/altaManual/'+ idraw, data ,{headers: this.getHeaders()})
    .then(response => response.data);
  }



  getPymesDiarias(desde, hasta = "") {
    return axios
    .get('/api/pymesdiarias/' + desde + '' + hasta)
    .then(response => response.data);
  }


  getEmpresaFichaHistorico(cuit, numeroTransaccion) {
    let route = "/api/empresas/fichahistorico/" + cuit;
    let params = {};
    if (numeroTransaccion !== "") {
      params.transaccion = numeroTransaccion;
    }
    return axios.get(route, { params, headers: this.getHeaders() }).then(response => response.data);
  }

}

const webService = new WebService();

export default webService;
