import React, { Component } from "react";

import DefaultLayoutHeader from "./defaultlayoutheader";
import DefaultLayoutSidebar from "./defaultlayoutsidebar";
import DefaultLayoutFooter from "./defaultlayoutfooter";

class DefaultLayout extends Component {
  state = {};

  render() {
    return (
      <div>
        <DefaultLayoutHeader />
        <div className="wrapper">
          {this.renderSideBar()}
          <div className="w-100">{this.props.children}</div>
        </div>
        <DefaultLayoutFooter />
      </div>
    );
  }

  renderSideBar() {
    if (this.props.showSidebar === true) {
      return <DefaultLayoutSidebar  />;
    }
    return null;
  }
}

export default DefaultLayout;
