

class WebServiceCache {

  _buildCachedName(url, params) {
    return btoa(JSON.stringify({ url: url, params: params }));
  }

  getCached(url, params) {
    let cachedName = this._buildCachedName(url, params);
    let cachedString = localStorage.getItem(cachedName);
    if (cachedString === null) {
      return cachedString;
    }
    let cachedItem = JSON.parse(cachedString);
    let expirationDate = new Date(cachedItem.date);
    if (new Date() > expirationDate) {
      return null;
    }
    return new Promise(function(resolve) {
      var response = {
        data: cachedItem.data,
      };
      resolve(response);
    });
  }

  setCached(url, params, responseobject, expirationTimeSecs) {
    let expirationDate = new Date();
    expirationDate.setSeconds(expirationDate.getSeconds() + expirationTimeSecs);
    let cachedName = this._buildCachedName(url, params);
    localStorage.setItem(cachedName, JSON.stringify({ expirationDate: expirationDate.toJSON(), data: responseobject }));
    return responseobject;
  }
}

const webServiceCache = new WebServiceCache();

export default webServiceCache;
