import { createBrowserHistory } from "history";

const history = createBrowserHistory();
let lastPathName = "";

history.listen((location, action) => {
  let currentPathName = location.pathname;
  if (lastPathName !== currentPathName) {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }
  lastPathName = currentPathName;
});

export default history;
