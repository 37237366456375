import moment from "moment";

const isExisty = function(value) {
  return value !== null && value !== undefined;
};

const isEmpty = function(value) {
  if (value instanceof Array) {
    return value.length === 0;
  }
  return value === "" || !isExisty(value);
};

const isEmptyTrimed = function(value) {
  if (typeof value === "string") {
    return value.trim() === "";
  }
  if (typeof value === "number") {
    return false;
  }
  return true;
};

const rules = {
  required: value => !isEmptyTrimed(value),
  isString: value => !isEmpty(value) || typeof value === "string" || value instanceof String,

  minLength: (value, length) => value === "" || (rules.isString(value) && value.length >= length),
  maxLength: (value, length) => value === "" || (rules.isString(value) && value.length <= length),

  email: value => {
    var res = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return rules.matchRegexp(value, res);
  },

  matchRegexp: (value, regexp) => {
    const validationRegexp = regexp instanceof RegExp ? regexp : new RegExp(regexp);
    return isEmpty(value) || validationRegexp.test(value);
  },

  date: (value, format) => {
    if (value === "") {
      return true;
    }
    let m = moment(value, format);
    return m.isValid();
  },

  cuit: value => {
    if (value === "") {
      return true;
    }

    let cuit = value + "";

    if (cuit.length === 13 && cuit[2] === "-" && cuit[11] === "-") {
      cuit = cuit.replace(/-/g, "");
    }

    if (cuit.length !== 11) {
      return false;
    }

    let validacionStr = "5432765432";

    let total = 0;
    for (let i = 0; i < 10; i++) {
      total += parseInt(cuit[i]) * parseInt(validacionStr[i]);
    }

    total = 11 - (total - parseInt(total / 11) * 11);

    if (total === 11) {
      total = 0;
    }
    if (total === 10) {
      total = 9;
    }
    return total === parseInt(cuit[10]);

  },

  /*
  isEmpty: value => isEmpty(value),
  trim: value => !isEmptyTrimed(value),

  isNumber: value => rules.matchRegexp(value, /^-?[0-9]\d*(\d+)?$/i),
  isFloat: value => rules.matchRegexp(value, /^(?:[1-9]\d*|0)?(?:\.\d+)?$/i),
  isPositive: value => {
    if (isExisty(value)) {
      return (rules.isNumber(value) || rules.isFloat(value)) && value >= 0;
    }
    return true;
  },
  maxNumber: (value, max) => isEmpty(value) || parseInt(value, 10) <= parseInt(max, 10),
  minNumber: (value, min) => isEmpty(value) || parseInt(value, 10) >= parseInt(min, 10),
  maxFloat: (value, max) => isEmpty(value) || parseFloat(value) <= parseFloat(max),
  minFloat: (value, min) => isEmpty(value) || parseFloat(value) >= parseFloat(min),
  */
};

export const Validators = {
  required: { func: rules.required, params: [], error: "Campo obligatorio" },
  minLength: length => {
    return { func: rules.minLength, params: [length], error: "El campo debe tener " + length + " caracteres" };
  },
  date: format => {
    return { func: rules.date, params: [format], error: "Formato de fecha inválido. Usar " + format };
  },
  email: { func: rules.email, params: [], error: "Email inválido" },
  matchRegexp: { func: rules.matchRegexp, params: [], error: "Formato inválido" },
  cuit: { func: rules.cuit, params: [], error: "CUIT inválido" },
};

export class FormValidation {
  form = null;

  constructor(form) {
    this.form = form;
    this.checkForm();
  }

  checkFormField(field) {
    this.form[field].errors = [];
    if (this.form[field].validations) {
      for (let validation of this.form[field].validations) {
        if (validation.func) {
          if (!validation.func(this.form[field].value, ...validation.params)) {
            this.form[field].errors.push(validation.error);
          }
        }
      }
    }
    this.form[field].invalid = this.form[field].errors.length > 0 && this.form[field].touched === true;
  }

  checkForm(touched = false) {
    for (let field in this.form) {
      if (touched === true) {
        this.form[field].touched = true;
      }
      this.checkFormField(field);
    }
  }

  setValue(field, value) {
    this.form[field].value = value;
    this.checkFormField(field);
  }

  setValidations(field, validations) {
    this.form[field].validations = validations;
    this.checkFormField(field);
  }

  setTouched(field) {
    this.form[field].touched = true;
    this.checkFormField(field);
  }

  getClone() {
    return JSON.parse(JSON.stringify(this.form));
  }

  getValue() {
    let ret = {};
    for (let field in this.form) {
      ret[field] = this.form[field].value;
    }
    return ret;
  }

  isValid() {
    for (let field in this.form) {
      if (this.form[field].invalid) {
        return false;
      }
    }
    return true;
  }
}

export default FormValidation;
