import React, { Component } from "react";

import brandLogo from "../../../assets/img/logos_MEcon SIDP color 21.png";

class DefaultLayoutFooter extends Component {
  state = {
    leftColumn: [
      { text: "La Secretaría", url: "https://www.argentina.gob.ar/produccion" },
      { text: "Datos Productivos", url: "https://www.argentina.gob.ar/produccion/datos-productivos" },
      { text: "Trámites", url: "https://www.argentina.gob.ar/produccion/programasytramites" },
      { text: "Registro de obsequios y viajes", url: "https://www.argentina.gob.ar/anticorrupcion/obsequiosyviajes" },
    ],
    rightColumn: [
      { text: "Acerca de este sitio", url: "https://www.argentina.gob.ar/acerca" },
      { text: "Términos y condiciones", url: "https://www.argentina.gob.ar/terminos-y-condiciones" },
      { text: "Ayuda", url: "https://www.argentina.gob.ar/ayuda" },
    ],
    currentYear: new Date().getFullYear(),
  };

  render() {
    const { currentYear } = this.state;

    return (
      <footer>
        <section className="footer" style={{ marginTop:"30px", padding:"0px",backgroundColor: "#242C4F", borderTop: "4px solid #E7BA61" }}>
          <section className="footer__copyright" style={{ paddingTop:"35px"}}>
            <div className="container">
              <div className="row">
                <div className="col-12">
                <center>
                  <p style={{ color: "#FFFFFF", fontFamily: "Lora, serif", fontSize: "12px", marginBottom: "0px" }}>{currentYear} | Ministerio de Economía - Secretaría de la Pequeña y Mediana Empresa, Emprendedores y Economía del Conocimiento | Av. Pres. Julio A. Roca 651 | (C1067ABB) | CABA | Tel. 0800-333-7963 | <a href="#" style={{ color: "#FFFFFF" }}>regpyme@produccion.gob.ar</a></p>
                </center>
                </div>
              </div>
            </div>
          </section>
        </section>
      </footer>
    );
  }
}

export default DefaultLayoutFooter;
