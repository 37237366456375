// eslint-disable-next-line no-unused-vars
const configLocal = {
  clientId: "5c17f372496cb8006a6343d4",
  clientSecret: "6M7B653J784JTjZOpuvjUHRg3zAJEpJepGYU3Mbp",
  loginUser: "",
  loginPass: "",
  baseURL: "http://127.0.0.1:8189/registropyme/public/",
};

// eslint-disable-next-line no-unused-vars
const configHomo = {
  clientId: "5c0aa5e483de9e049626cf64",
  clientSecret: "k9BxWc371F2QkWCpkJ9EBxatSCRD11sGGfbGUTxZ",
  loginUser: "",
  loginPass: "",
  baseURL: "http://registropyme.hml.produccion.gob.ar/",
};

// eslint-disable-next-line no-unused-vars
const configLab = {
  clientId: "5bd37157e79ea70997452054",
  clientSecret: "nYHeOSaFkQlr6dTJX7M5RTYOpl5mQufOKMjCAN3G",
  loginUser: "",
  loginPass: "",
  baseURL: "http://api.registropyme2.lab.produccion.gob.ar/",
};

// eslint-disable-next-line no-unused-vars
const configProduccion = {
  clientId: "5c17f372496cb8006a6343d4",
  clientSecret: "6M7B653J784JTjZOpuvjUHRg3zAJEpJepGYU3Mbp",
  loginUser: "",
  loginPass: "",
  baseURL: "https://pyme.produccion.gob.ar/",
};


const config = configProduccion;

export default config;
