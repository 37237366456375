import React, { Component } from "react";
import { Link } from "react-router-dom";

import history from "../../../utils/history";
import eventsHub from "../../../utils/eventshub";

class DefaultLayoutSidebar extends Component {
  state = {
    showToggle: true,
    largeSideBar: false,
    hideSideBar: false,
    items: [
      { icon: "fa-tachometer", text: "Dashboard", url: "/dashboard" },
      { icon: "fa-file", text: "Ficha de empresa", url: "/fichaempresa", disabled: true },
      { icon: "fa-pencil", text: "Modificación manual", url: "/modificacionmanual" },
      { icon: "fa-ban", text: "Bajas", url: "/bajas" },
      { icon: "fa-certificate", text: "Certificado", url: "/certificados", role: "admin"},
      { icon: "fa-bar-chart", text: "Reportes", url: "/reportes", role:"super-admin" },
      { icon: "fa-cog", text: "Configuración", url: "/configuracion", role:"super-admin" },
    ],
    userRoles: [],
  };
  historyUnlisten = null;
  onFichaEmpresaChanged = null;
  onUserRoleChanged = null;

  updateMenuActive() {
    let currentPath = history.location.pathname;
    const items = this.state.items.map(item => {
      item.active = item.url.indexOf(currentPath) === 0;
      return item;
    });

    this.setState({ items });
  }

  componentWillUnmount() {
    if (this.onFichaEmpresaChanged) {
      this.onFichaEmpresaChanged.unsubscribe();
      this.onFichaEmpresaChanged = null;
    }

    if (this.historyUnlisten) {
      this.historyUnlisten();
      this.unlisten = null;
    }
  }

  componentDidMount() {
    this.onFichaEmpresaChanged = eventsHub.onFichaEmpresaChanged().subscribe(value => {
      let items = [];
      for (let item of this.state.items) {
        if (item.text === "Ficha empresa") {
          items.push({
            ...item,
            url: value
              ? "/fichaempresa?cuit=" +
                encodeURIComponent(value.cuit) +
                "&transaccion=" +
                encodeURIComponent(value.transaccion)
              : "",
            disabled: value === null,
          });
        } else {
          items.push(item);
        }
      }
      this.setState({ items });
    });

    this.updateMenuActive();
    this.historyUnlisten = history.listen(() => {
      this.updateMenuActive();
    });

    this.onUserRoleChanged = eventsHub.onUserRoleChanged().subscribe(value => {
      this.setState({ userRoles: value });
    });
  }

  toggleSideBarSize() {
    this.setState({ largeSideBar: !this.state.largeSideBar });
  }

  render() {
    let navClass = "page-sidebar d-none d-md-block ";
    if (!this.state.largeSideBar) {
      navClass += "sidebar-compact";
    }
    if (this.state.hideSideBar) {
      navClass += "sidebar-hide";
    }

    return (
      <nav className={navClass}>
        <ul className="sidebar-menu">
          {this.state.showToggle === true && (
            <li className="sidebar-menu-item">
              <button type="button" onClick={this.toggleSideBarSize.bind(this)}>
                <i className="fa fa-fw fa-bars" />
              </button>
            </li>
          )}

          {this.state.items.map((item, index) => {
            let itemClassName = "sidebar-menu-item";
            if (item.active === true) {
              itemClassName += " active";
            }

            if (item.role === "admin" && !this.state.userRoles.includes('admin')) {
              return false;
            }

            if (item.role === "super-admin" && !this.state.userRoles.includes('super-admin')) {
              return false;
            }

            if (item.disabled === true) {
              itemClassName += " disabled";
              return (
                <li key={index} className={itemClassName}>
                  <span>
                    <i className={"fa fa-fw " + item.icon} />
                    <span>{item.text}</span>
                  </span>
                </li>
              );
            }

            return (
              <li key={index} className={itemClassName}>
                <Link to={item.url}>
                  <i className={"fa fa-fw " + item.icon} />
                  <span>{item.text}</span>
                </Link>
              </li>
            );
          })}
        </ul>
      </nav>
    );
  }
}

export default DefaultLayoutSidebar;
