import React, { Component } from "react";
import { Link } from "react-router-dom";

import eventsHub from "../../../utils/eventshub";
import UserMenu from "../../navbarcontrols/usermenu/usermenu";
// import AlertsMenu from "../../navbarcontrols/alertsmenu/alertsmenu";
import NavBarSearch from "../../navbarcontrols/navbarsearch/navbarsearch";
import brandLogo from "../../../assets/img/logos_MEcon SIDP color 21.png";

class DefaultLayoutHeader extends Component {
  state = {
    loggedIn: false,
  };
  onLoggedInChanged;

  componentDidMount() {
    this.onLoggedInChanged = eventsHub.onLoggedInChanged().subscribe(value => {
      this.setState({ loggedIn: value });
    });
  }

  componentWillUnmount() {
    this.onLoggedInChanged.unsubscribe();
    this.onLoggedInChanged = null;
  }

  render() {
    return (
      <nav className="navbar navbar-expand-md navbar-produccion fixed-top" style={{borderBottom: "4px solid #E7BA61"}}>
        <div className="container">
          <Link to={this.state.loggedIn ? "/dashboard" : "/login"} className="navbar-brand">
            <img src={brandLogo} alt="Ministerio de Producción" style={{height: 48,marginTop:'2px'}}/>
          </Link>

          {this.renderOptions()}
        </div>
      </nav>
    );
  }

  renderOptions() {
    if (!this.state.loggedIn) {
      return null;
    }
    return (
      <React.Fragment>
        <div className="ml-auto" />
        <div className="collapse navbar-collapse">
          <NavBarSearch className="ml-auto" />
        </div>
        {/* <AlertsMenu /> */}
        <UserMenu />
      </React.Fragment>
    );
  }
}

export default DefaultLayoutHeader;
