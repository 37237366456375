import React, { Component } from "react";
import { Container, Form, Label, Input, InputGroupAddon, FormGroup, InputGroup, Button } from "reactstrap";

import DefaultLayout from "../../components/layouts/defaultlayout/defaultlayout";
import { Validators, FormValidation } from "../../utils/formvalidation";
import FormError from "../../components/formerror/formerror";
import webService from "../../utils/webservice";
import history from "../../utils/history";
import config from "../../utils/config";

class PageLogin extends Component {
  state = {
    passwordType: "password",
    loginError: false,
    loginErrorId: null,
    form: null,
  };

  constructor(props) {
    super(props);

    let form = {
      email: { value: config.loginUser, validations: [Validators.required, Validators.email] },
      password: { value: config.loginPass, validations: [Validators.required, Validators.minLength(6)] },
      captcha: { value: "" },
    };
    this.formValidation = new FormValidation(form);
    this.state.form = this.formValidation.getClone();
  }

  showPassword(show) {
    this.setState({ passwordType: show ? "text" : "password" });
  }

  onFormHandleChange(name, value) {
    this.formValidation.setValue(name, value);
    this.setState({ form: this.formValidation.getClone() });
  }

  onFormHandleBlur(name) {
    this.formValidation.setTouched(name);
    this.setState({ form: this.formValidation.getClone() });
  }

  onFormHandleSubmit(event) {
    event.preventDefault();
    this.formValidation.checkForm(true);
    if (!this.formValidation.isValid()) {
      this.setState({ form: this.formValidation.getClone() });
      return;
    }

    let formValue = this.formValidation.getValue();
    webService
      .login(formValue.email, formValue.password)
      .then(response => {
        history.push("/dashboard");
      })
      .catch(error => {
        if (error.response && error.response.data) {
          this.setState({ 
            loginError: true,
            loginErrorId: error.response.status,

           });
          //alert(error.response.data.message);
        } else {
          alert("Error al comunicarse con el servidor");
        }
      });
  }

  renderIncompleteAlert() {
    if (!this.state.loginError) {
      return null;
    }

    console.log(this.state.loginErrorId);
    
    return (

      <div className="alert alert-danger">
        <div className="media">
          <div className="media-left">
            <i className="fa fa-exclamation-circle fa-fw fa-3x"></i>
          </div>
          <div className="media-body">
            <b>
              {this.state.loginErrorId === 401
              ? "Inicio de sesión inválido"
              : "Error de conexión"
              }
            </b>
            <br />
            {this.state.loginErrorId === 401 
              ? <div>Usuario y contraseña inválido, por favor ingrese su usuario y contraseña nuevamente.</div>
              : <div>Hubo un problema en la comunicación con el servidor, por favor pruebe recargando la página, en caso de persistir, cree un ticket en  <a href="http://redmine.produccion.gob.ar/projects/registro-pyme-afip" target="_blank" rel="noopener noreferrer">redmine.produccion.gob.ar/projects/registro-pyme-afip</a></div>
            }
            
        </div>
        </div>
      </div>

    );
  }

  render() {
    return (
      <DefaultLayout showSidebar={false}>
        <Container>
        <div className="my-3">{this.renderIncompleteAlert()}</div>
          <div className="datos-box bg-white w-50 mx-auto my-5">
          
            <h2>Ingreso</h2>
            <Form noValidate onSubmit={this.onFormHandleSubmit.bind(this)}>
              <FormGroup>
                <Label>Email</Label>
                <Input
                  type="email"
                  value={this.state.form.email.value}
                  invalid={this.state.form.email.invalid}
                  onChange={event => this.onFormHandleChange("email", event.target.value)}
                  onBlur={() => this.onFormHandleBlur("email")}
                />
                <FormError errors={this.state.form.email.errors} />
              </FormGroup>
              <FormGroup>
                <Label>Contraseña</Label>
                <InputGroup>
                  <Input
                    type={this.state.passwordType}
                    value={this.state.form.password.value}
                    invalid={this.state.form.password.invalid}
                    onChange={event => this.onFormHandleChange("password", event.target.value)}
                    onBlur={() => this.onFormHandleBlur("password")}
                  />
                  <InputGroupAddon addonType="append">
                    <Button
                      type="button"
                      color="white"
                      onMouseDown={this.showPassword.bind(this, true)}
                      onMouseUp={this.showPassword.bind(this, false)}
                      onMouseLeave={this.showPassword.bind(this, false)}
                    >
                      <i className="fa fa-eye" />
                    </Button>
                  </InputGroupAddon>
                  <FormError errors={this.state.form.password.errors} />
                </InputGroup>
              </FormGroup>

              <div className="text-center">
                <Button type="submit" color="primary" onClick={this.handleOnClick}>
                  INICIÁ SESIÓN
                </Button>
              </div>
            </Form>
          </div>
          
        </Container>
      </DefaultLayout>
    );
  }
}

export default PageLogin;
