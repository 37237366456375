import React, { Component } from "react";
import { Form, Input } from "reactstrap";

import FormError from "../../formerror/formerror";
import { FormValidation, Validators } from "../../../utils/formvalidation";
import history from "../../../utils/history";
import eventsHub from "../../../utils/eventshub";
import webService from "../../../utils/webservice";

class NavBarSearch extends Component {
  state = {
    form: null,
  };
  formValidation = null;

  constructor(props) {
    super(props);

    let form = {
      cuit: {
        value: "",
        validations: [Validators.cuit],
      },
    };
    this.formValidation = new FormValidation(form);
    this.state.form = this.formValidation.getClone();
  }

  onFormHandleChange(name, value) {
    this.formValidation.setValue(name, value);
    this.setState({ form: this.formValidation.getClone() });
  }

  onFormHandleBlur(name) {
    this.formValidation.setTouched(name);
    this.setState({ form: this.formValidation.getClone() });
  }

  onFormHandleSubmit(event) {
    event.preventDefault();
    this.formValidation.checkForm(true);
    if (!this.formValidation.isValid()) {
      this.setState({ form: this.formValidation.getClone() });
      return;
    }

    let formValue = this.formValidation.getValue();
    webService
      .getEmpresaFicha(formValue.cuit, "")
      .then(fichaEmpresa => {
        eventsHub.setFichaEmpresa(fichaEmpresa);
        history.push("/fichaempresa?cuit=" + encodeURIComponent(formValue.cuit));
      })
      .catch(error => {
        alert(error.response.data.message);
      });
  }

  render() {
    let ulClassName = "navbar-nav";
    if (this.props.className) {
      ulClassName += " " + this.props.className;
    }

    return (
      <ul className={ulClassName}>
        <li className="nav-item mx-2">
          <Form className="form-inline navbar-searchform" onSubmit={this.onFormHandleSubmit.bind(this)}>
            <div className="input-group">
              <Input
                type="text"
                placeholder="Ingresar CUIT"
                value={this.state.form.cuit.value}
                invalid={this.state.form.cuit.invalid}
                onChange={event => this.onFormHandleChange("cuit", event.target.value)}
                onBlur={() => this.onFormHandleBlur("cuit")}
              />
              <div className="input-group-append">
                <button type="submit" className="btn btn-primary">
                  <i className="fa fa-search" />
                </button>
              </div>
              <FormError
                style={{
                  position: "absolute",
                  left: "12px",
                  top: "32px",
                }}
                errors={this.state.form.cuit.errors}
              />
            </div>
          </Form>
        </li>
      </ul>
    );
  }
}

export default NavBarSearch;
