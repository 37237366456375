import React, { Component } from "react";

class FormError extends Component {
  render() {
    return (
      <p className="invalid-feedback" style={this.props.style}>
        {this.props.errors.map((error, index) => (
          <span key={index}>
            {error}
            <br />
          </span>
        ))}
      </p>
    );
  }
}

export default FormError;
