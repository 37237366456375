import React from "react";
import Loadable from "react-loadable";

function Loading() {
  return <div className="spinner-container"><i className="fa fa-spinner fa-spin fa-5x" /> </div>;
}

const PageLogin = Loadable({
  loader: () => import("../pages/login/login"),
  loading: Loading,
});

const PageDashboard = Loadable({
  loader: () => import("../pages/dashboard/dashboard"),
  loading: Loading,
});

const PageFichaEmpresa = Loadable({
  loader: () => import("../pages/fichaempresa/fichaempresa"),
  loading: Loading,
});

const PageModificacionManual = Loadable({
  loader: () => import("../pages/modificacionmanual/modificacionmanual"),
  loading: Loading,
});

const PageModificacionManualForm = Loadable({
  loader: () => import("../pages/modificacionmanual/modificacionmanual-form"),
  loading: Loading,
});

const PageModificacionManualCategorizar = Loadable({
  loader: () => import("../pages/modificacionmanual/modificacionmanual-categorizar"),
  loading: Loading,
});

const PageModificacionManualRectificar = Loadable({
  loader: () => import("../pages/modificacionmanual/modificacionmanual-rectificar"),
  loading: Loading,
});

const PageBajas = Loadable({
  loader: () => import("../pages/bajas/bajas"),
  loading: Loading,
});

const PageBajaManualBusqueda = Loadable({
  loader: () => import("../pages/bajas/baja-manual-busqueda"),
  loading: Loading,
});

const PageBajaManualSolicitud = Loadable({
  loader: () => import("../pages/bajas/baja-manual-solicitud"),
  loading: Loading,
});

const PageReportes = Loadable({
  loader: () => import("../pages/reportes/reportes"),
  loading: Loading,
});

const PageConfiguracion = Loadable({
  loader: () => import("../pages/configuracion/configuracion"),
  loading: Loading,
});

const PageNotifications = Loadable({
  loader: () => import("../pages/notifications/notifications"),
  loading: Loading,
});

const PageCertificados = Loadable({
    loader: () => import("../pages/certificado/certificados"),
    loading: Loading,
});

const PageCertificadoBusqueda = Loadable({
    loader: () => import('../pages/certificado/certificado-busqueda'),
    loading: Loading,
});

const PageCertificadoSolicitud = Loadable({
  loader: () => import('../pages/certificado/certificado-solicitud'),
  loading: Loading,
});

const PageChangelog = Loadable({
  loader: () => import('../pages/changelog/changelog'),
  loading: Loading,
});

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  { path: "/login", name: "Login", component: PageLogin, exact: true },
  { path: "/dashboard", name: "Dashboard", component: PageDashboard, exact: true },
  { path: "/fichaempresa", name: "Ficha Empresa", component: PageFichaEmpresa, exact: true },
  { path: "/modificacionmanual", name: "Modificacion Manual", component: PageModificacionManual, exact: true },
  {
    path: "/modificacionmanual/form",
    name: "Modificacion Manual Formulario",
    component: PageModificacionManualForm,
    exact: true,
  },
  {
    path: "/modificacionmanual/categorizar",
    name: "Categorizar",
    component: PageModificacionManualCategorizar,
    exact: true,
  },
  {
    path: "/modificacionmanual/rectificar",
    name: "Rectificar",
    component: PageModificacionManualRectificar,
    exact: true,
  },
  { path: "/bajas", name: "Bajas", component: PageBajas, exact: true },
  { path: "/bajas/solicitud", name: "Baja Manual Solicitud", component: PageBajaManualSolicitud, exact: true },
  { path: "/bajas/busqueda", name: "Baja Manual Busqueda", component: PageBajaManualBusqueda, exact: true },
  { path: "/reportes", name: "Reportes", component: PageReportes, exact: true },
  { path: "/notifications", name: "Notificaciones", component: PageNotifications, exact: true },
  { path: "/certificados", name: "Certificados", component: PageCertificados , exact: true},
  { path: "/certificados/solicitud", name: "Certificado Solicitud", component: PageCertificadoSolicitud , exact: true},
  { path: "/certificados/busqueda", name: "Certificados Busqueda", component: PageCertificadoBusqueda , exact: true},
  { path: "/configuracion", name: "Configuración", component: PageConfiguracion , exact: true},
  { path: "/changelog", name: "Changelog", component: PageChangelog, exact: true },
];

export default routes;
